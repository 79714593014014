import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { colors, B2OpenSansBlue } from "../../styles/helpers"
import MobileSubMenu from "./MobileSubMenu"

const NavItem = styled.li`
  position: relative;
  width: 100%;
  align-self: center;
  font-size: 1.8rem;
  text-align: center;
  overflow: hidden;

  a {
    display: block;
    padding: 1rem 2rem;
    color: #fff;
    font-size: 1.8rem;
    border-bottom: 0.15rem solid ${colors.white};

    &:hover {
      color: ${props => props.theme.colorSecondary};
    }
  }

  .sub-wrap {
    button {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 7.5rem;
      height: 7.5rem;
      background-color: transparent;
      font-size: 3rem;
      font-weight: bold;
      color: ${colors.white};
      border: none;
      border-radius: 0 !important;
      line-height: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .subContainer {
    width: 100%;
    height: 100%;
  }

  a {
    ${B2OpenSansBlue};
    display: block;
    width: 100%;
    padding: 2rem;
    color: ${colors.white};
    text-transform: uppercase;

    &:hover {
      color: ${colors.colorTertiary};
    }

    &[aria-current="page"] {
      color: ${colors.colorSecondary} !important;
      background-color: rgb(36, 130, 150) !important;

      &:hover {
        cursor: default;
      }
    }

    &.mobile-highlight {
      background-color: ${colors.colorTertiary};

      &:hover {
        color: ${colors.black} !important;
      }
    }
  }
`

const MobileNavItem = ({ item }) => {
  const [subActive, setSubActive] = useState(false)

  const handleToggleActive = () => {
    setSubActive(!subActive)
  }

  return (
    <NavItem>
      <Link to={`/${item.object_slug}`}>{item.title}</Link>
      {item.object_slug === "ministries" && (
        <div className="sub-wrap">
          <button
            onClick={() => {
              handleToggleActive()
            }}
          >
            {subActive ? <>&#8722;</> : <>&#43;</>}
          </button>
          <div className="subContainer">
            <MobileSubMenu subActive={subActive} />
          </div>
        </div>
      )}
    </NavItem>
  )
}

export default MobileNavItem
