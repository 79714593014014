import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const LogoBlackWrap = styled.div`
  width: 100%;
  height: 100%;
`

const getData = graphql`
  {
    logoBlack: file(relativePath: { eq: "WFC-Logo-Black.png" }) {
      publicURL
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const LogoBlack = () => {
  const data = useStaticQuery(getData)
  const imageData = data.logoBlack.childImageSharp.fluid
  const imageDataPub = data.logoBlack.publicURL
  return (
    <LogoBlackWrap>
      {/* <Img fluid={imageData} alt="Black Fields Calvary Church" /> */}
      <img src={imageDataPub} alt="Black Fields Calvary Church" />
    </LogoBlackWrap>
  )
}

export default LogoBlack
