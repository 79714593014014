import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import LogoWhite from "../Logos/LogoWhite"
import LogoBlack from "../Logos/LogoBlack"

const HeaderLogoWrap = styled.div`
  align-self: center;
  width: 25rem;
  margin: 2rem auto;
  padding: 0 2rem;

  @media (min-width: 768px) {
    width: 30rem;
  }

  h1 {
    margin: 0;
    padding: 0;

    a {
      color: transparent !important;

      &:hover {
        color: transparent !important;
      }
    }

    span {
      display: block;
      position: absolute;
      left: -999%;
    }
  }
`

const HeaderTopLogo = ({ siteTitle }) => {
  return (
    <HeaderLogoWrap>
      <h1>
        <Link to="/">
          <LogoBlack />
        </Link>
        <span>{siteTitle}</span>
      </h1>
    </HeaderLogoWrap>
  )
}

export default HeaderTopLogo
