import React from "react"
import styled from "styled-components"
import {
  colors,
  H2LatoGold,
  B1OpenSansWhite,
  H4LatoGold,
  H4LatoBlue,
} from "../../styles/helpers"

const DonateModal = styled.div`
  display: ${props => (props.isActive ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  z-index: 99999999;
  overflow: scroll;

  .modalBackground {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .modalInner {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 4rem;
    background-color: ${colors.colorNew};
    opacity: 0.95;
    box-shadow: 8px 9px 19px 0 rgba(0, 0, 0, 0.49);
    z-index: 999999999;
    overflow: scroll;

    @media (min-width: 768px) {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 9rem auto 0;
      transform: translate(-50%, -50%);
    }

    @media (min-width: 1025px) {
      max-width: 110rem;
      margin: 9rem auto 0;
      padding: 8rem;
    }

    .closeButton {
      position: absolute;
      top: 1rem;
      right: 1rem;

      button {
        width: 3.5rem;
        height: 3.5rem;
        transition: all 0.3s ease;
        border-radius: 50%;
        border: 0.25rem solid ${colors.black};
        background-color: ${colors.black};
        color: ${colors.white};
        font-weight: 700;
        cursor: pointer;

        &:hover {
          background-color: ${colors.white};
          color: ${colors.black};
        }
      }
    }

    &__content {
      overflow: scroll;

      @media (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
      }
    }

    .creditCard {
      h3 {
        ${H4LatoGold};
        color: ${colors.black};
      }

      p {
        span {
          color: ${colors.colorSecondary};
          color: ${colors.black};
          font-weight: bold;
        }
      }
    }
  }
`

const Details = styled.div`
  width: 100%;
  overflow: scroll;

  @media (min-width: 768px) {
    width: calc(50%);
  }
  .title {
    h2 {
      ${H2LatoGold};
      color: ${colors.black};
    }
  }

  .subTitle {
    h3 {
      ${H4LatoGold};
      color: ${colors.black};
    }
  }

  .paragraph {
    p {
      ${B1OpenSansWhite};
    }

    a {
      ${B1OpenSansWhite};
      color: ${colors.black};

      &:hover {
        color: ${colors.colorAccent};
      }
    }

    p.paragraph__contact {
      color: ${colors.black};
    }

    &__wysiwyg {
      h3 {
        ${H4LatoGold};
        color: ${colors.black};
      }
    }
  }
`

const Explain = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    width: calc(50%);
  }

  .bubbleWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 5rem;
    padding: 2rem;
    border-radius: 2rem;
    background-color: ${colors.black};

    @media (min-width: 768px) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: 5rem;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: -3rem;
      width: 0;
      height: 0;
      border-left: 3rem solid transparent;
      border-right: 3rem solid transparent;
      border-bottom: 3rem solid ${colors.black};
      content: "";
    }
  }

  h3 {
    ${H4LatoBlue};
    color: ${colors.colorNew};
  }

  p {
    ${B1OpenSansWhite};
  }
`

const Donate = ({ isActive, handleModalState, title, content, side }) => {
  return (
    <DonateModal isActive={isActive}>
      <div className="modalInner">
        <div className="modalInner__content">
          <Details>
            <div className="title">
              <h2>{title}</h2>
            </div>
            <div className="paragraph">
              <div
                className="paragraph__wysiwyg"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>

            <div className="paragraph">
              <div className="creditCard">
                <h3>To Send By Credit Card</h3>
                <p>
                  Please click the "give" button below to send your donation by{" "}
                  <span>credit card</span>
                </p>
                <iframe
                  src="https://wallet.subsplash.com/ui/embed/QX268Q"
                  width="100%"
                  height="200"
                  style={{ border: "none", overflow: "hidden" }}
                  frameBorder="0"
                  scrolling="no"
                />
              </div>
            </div>
          </Details>
          <Explain>
            <div className="bubbleWrap">
              <div className="bubbleWrap__inner">
                <div dangerouslySetInnerHTML={{ __html: side }} />
              </div>
            </div>
          </Explain>
        </div>
        <div className="closeButton">
          <button onClick={handleModalState}>&#88;</button>
        </div>
      </div>
      <div className="modalBackground" onClick={handleModalState} />
    </DonateModal>
  )
}

export default Donate
