import React from "react"
import styled from "styled-components"
import { colors } from "../../styles/helpers"

const GiftWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  svg {
    width: 100%;
    max-width: 5rem;
    max-height: 3rem;
    margin: 0 2rem;
  }

  .prefix__cls-1 {
    transition: all 0.3s ease-in;
    fill: ${colors.colorPrimary};
    cursor: pointer;
  }

  &:hover {
    .prefix__cls-1 {
      fill: ${colors.colorTertiary};
    }
  }
`

const Gift = () => {
  return (
    <GiftWrap>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="287.458"
        height="148.619"
        viewBox="0 0 287.458 148.619"
      >
        <g data-name="Group 3514" transform="translate(-220.014 -704.087)">
          <path
            d="M495.589 750.792c-4.605-4.392-9.843-3.705-15.716.106-.091.058-.179.121-.271.181h-20.636v-13.442h17.743v-9.684h-17.743v-13.172h23.17v-10.076H445.8v56.445h20.536c-10.239 8.66-19.21 17.687-30.381 23.347a13.4 13.4 0 013.808 5.4c5.664 14.923-14.551 24.553-26.72 22.488l-39.9-6.72c-9.789-1.641-19.526-1.323-28.628 2.539-3.334 1.378-4.814-3.81-1.428-5.184 9.63-3.862 20-4.445 30.954-2.595l18.629 3.175 21.269 3.547c8.522 1.482 24.766-5.081 20.849-15.4-1.323-3.44-5.927-6.509-12.169-7.779-9.261-1.8-19.315-3.388-29.949-5.716a188.874 188.874 0 01-41.433-13.6q-4.208-1.977-8.413-3.549a35.653 35.653 0 003.59-.769 29.169 29.169 0 005-1.88 29.685 29.685 0 004.512-2.729V731.9h-20.071v7.207a2.183 2.183 0 00.7 1.665 2.7 2.7 0 001.938.661h5.578V750a23.331 23.331 0 01-3.95 1.236 22.307 22.307 0 01-4.8.467 16.828 16.828 0 01-6.567-1.26 14.482 14.482 0 01-5.189-3.657 17.164 17.164 0 01-3.429-5.89 23.764 23.764 0 01-1.239-7.982 22.914 22.914 0 011.122-7.377 16.587 16.587 0 013.2-5.682 14.233 14.233 0 015.016-3.657 16.278 16.278 0 016.627-1.3 28.889 28.889 0 013.562.2 17.671 17.671 0 015.832 1.721c.915.453 1.893.991 2.923 1.609a4.53 4.53 0 002.4.775 3.086 3.086 0 001.646-.467 4.187 4.187 0 001.3-1.273l3.8-5.776a25.445 25.445 0 00-3.875-3.02 26.08 26.08 0 00-4.782-2.4 32.109 32.109 0 00-5.716-1.589 36.177 36.177 0 00-6.623-.581 34.063 34.063 0 00-12.339 2.132 27.109 27.109 0 00-9.414 5.964 26.106 26.106 0 00-5.985 9.124 31.291 31.291 0 00-2.093 11.6 29.743 29.743 0 002.19 11.488 27.8 27.8 0 006.044 9.143 26.784 26.784 0 001.906 1.723 69.315 69.315 0 00-49.684 17.118c-6.774 5.82-10.636 8.943-19.156 12.7l-24.551 10.845c-4.288 1.906-2.169 16.085 4.71 31.7s15.927 26.774 20.213 24.87l39.632-17.463c8.678-3.81 20.532-5.555 34.553-1.587l43.868 12.488c13.547 3.862 28.785 5.714 41.273-.954l46.194-24.6C467.6 812.384 472.2 811.7 481.3 799l23.549-32.809c6.614-9.261-.319-18.574-9.26-15.399z"
            className="prefix__cls-1"
            data-name="Path 18878"
          ></path>
          <path
            d="M0 0h13.172v56.445H0z"
            className="prefix__cls-1"
            data-name="Rectangle 2317"
            transform="translate(365.065 704.705)"
          ></path>
          <path
            d="M342.363 760.863l22.626-56.445h-10.614a4.475 4.475 0 00-4.264 2.832l-10.847 30.214q-.695 1.977-1.413 4.3t-1.26 4.881q-.658-2.554-1.413-4.881t-1.491-4.3l-10.924-30.214a4.28 4.28 0 00-1.551-2.057 4.675 4.675 0 00-2.789-.775h-10.536l22.623 56.445z"
            className="prefix__cls-1"
            data-name="Path 18879"
            transform="translate(76.161 .287)"
          ></path>
        </g>
      </svg>
    </GiftWrap>
  )
}

export default Gift
